.chartRow {
  display: flex !important;
  flex-direction: column !important;
}
.chartRowHeader {
  display: flex;
  flex-direction: row;
  flex: 1;
  padding-bottom: 10px;
  padding-top: 10px;
  justify-content: space-between;
  align-items: center;
  /*
  border-bottom: solid 2px #bbd0ff;
  background-color: #bbd0fa;
  */
  border-top-right-radius: 3px;
  border-top-left-radius: 3px;

  background: linear-gradient(to bottom, #abaeb4, #a0a1a1);
  color: white;
  font-family: Arial, sans-serif;
}
.chartRowHeaderLabel1 {
}
.chartRowHeaderLabel2 {
  font-weight: bold;
  font-size: 24px;
}
.chartRowContent {
  display: flex;
  flex-direction: row;
  margin-top: 20px;
  margin-bottom: 20px;
}
.chartRowContentC1 {
  display: flex;
  flex: 0.7;
  align-items: center;
  justify-content: center;
}

.chartRowContentU {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.chartRowContentC2 {
  display: flex;
  flex: 0.3;
  align-items: center;
  justify-content: center;
}
